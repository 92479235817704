import PermissionDto, {
  AbilityResponse,
  transformBusinessUserAbilityResponseToDto
} from '@/dto/Auth/PermissionDto'

export type BusinessUserRoleResponse = {
  id: string
  name: string
  title: string
  level: number
  abilities: AbilityResponse[]
}

export default class RoleDto {
  public id: string
  public name: string
  public title: string
  public level: number
  public abilities: PermissionDto[]

  constructor(
    id: string,
    name: string,
    title: string,
    level: number,
    abilities: PermissionDto[]
  ) {
    this.id = id
    this.name = name
    this.title = title
    this.level = level
    this.abilities = abilities
  }
}

export function transformBusinessUserRoleResponseToDto(
  businessUserRole: BusinessUserRoleResponse
): RoleDto {
  return new RoleDto(
    businessUserRole.id,
    businessUserRole.name,
    businessUserRole.title,
    businessUserRole.level,
    businessUserRole.abilities.map((ability) =>
      transformBusinessUserAbilityResponseToDto(ability)
    )
  )
}
