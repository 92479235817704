<template>
  <span
    class="badge bg-opacity-10 text-uppercase"
    :class="boolean ? 'bg-success text-success' : 'bg-danger text-danger'"
  >
    {{ boolean ? t(truthy) : t(falsy) }}
  </span>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

defineProps({
  boolean: {
    type: Boolean,
    required: true
  },
  truthy: {
    type: String,
    default: 'Yes'
  },
  falsy: {
    type: String,
    default: 'No'
  }
})

const { t } = useI18n()
</script>
