import { LeadStatusColor, LeadStatusColorToClassMap } from '@/shared'

export function getColorSuffix(
  color: LeadStatusColor,
  transparent: boolean
): string | null {
  const className = LeadStatusColorToClassMap[color] ?? ''

  return transparent ? `${className}-transparent` : className
}

export function getColorBackground(
    color: LeadStatusColor | null
): string {
  if (color === null) {
    return ''
  }

  const className = LeadStatusColorToClassMap[color] ?? ''

  return className ? `gradient-${className}` : ''
}
