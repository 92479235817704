<template>
  <img v-if="currentPhoto" :src="currentPhoto" alt="avatar" class="user-image" />
  <base-lead-status-badge
    v-else-if="profile"
    :color="profile.color"
    :label="toInitials(profile.first_name, profile.last_name)"
    :square="false"
    :border="true"
    :size="size"
    :tooltip-text="`${profile.first_name} ${profile.last_name}`"
  />
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { BusinessUserProfileResponse } from '@/dto/Auth/ProfileDto'
import { toInitials } from '@/helpers/string.helper'
import { BadgeSize } from '@/shared'

const props = defineProps({
  profile: {
    type: [Object, null] as PropType<BusinessUserProfileResponse | null>,
    required: true
  },
  size: {
    type: String as PropType<BadgeSize>,
    default: 'md',
    required: false
  }
})

const currentPhoto = computed((): string | null => {
  if (props.profile?.photo) {
    return props.profile.photo.url
  }

  return null
})
</script>

<style lang="scss" scoped>
.user-image {
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  object-fit: cover;

  > span {
    font-size: 14px;
    font-weight: bold;
  }
}
</style>
