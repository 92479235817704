import { Actions, createComposable, Getters, Module, Mutations } from 'vuex-smart-module'
import DashboardRepository, {
  LocaleSettings,
  PersistedDashboardState
} from '@/store/Dashboard/dashboard.repository'
import {
  getNationalHolidays,
  GetNationalHolidaysResponseDto,
  NationalHolidayDataDto
} from '@/api/Business/holidays'
import { setLocale } from '@/i18n'
import { Locale, CountryCodeIso2 } from '@/shared'
import { SchedulerTimezonesDto } from '@/dto/Scheduler/Response/index'
import { CommonCountryResponseDto } from '@/dto/common/Country/Response'
import { RuntimeModule } from '@/store/moduleType'
import PSPDFKit, { Font } from 'pspdfkit'

const localDashboardStorage = DashboardRepository.getLocalInstance()

class DashboardState {
  isSubSidebarOpen = false
  isMainMenuHidden = true
  isGooglePlacesLoaded = false
  isGooglePlacesReady = false
  settings: PersistedDashboardState = new PersistedDashboardState()
  countryLocale: CommonCountryResponseDto | null = null
  abortController: AbortController = new AbortController()
  isStoreLoaded = true
  appVersion = import.meta.env.VITE_BUILD_VERSION ?? null
  holidays: NationalHolidayDataDto[] = []
  timezoneList: SchedulerTimezonesDto[] = []
  customFonts: Font[] = []
}

class DashboardGetters extends Getters<DashboardState> {
  get locale(): Locale {
    return <Locale>`${this.state.settings.locale.language}-${this.state.settings.locale.country}`
  }

  get localeLanguage(): string {
    return this.state.settings.locale.language
  }

  get localeCountry(): CountryCodeIso2 {
    return this.state.settings.locale.country
  }

  get countryLocale(): CommonCountryResponseDto | null {
    return this.state.countryLocale
  }

  get timezone(): string {
    return this.state.settings.timezone
  }

  get isSubSidebarOpen(): boolean {
    return this.state.isSubSidebarOpen
  }

  get isMainMenuHidden(): boolean {
    return this.state.isMainMenuHidden
  }

  get isGooglePlacesLoaded() {
    return this.state.isGooglePlacesLoaded
  }

  get isGooglePlacesReady() {
    return this.state.isGooglePlacesReady
  }

  get abortControllerSignal() {
    return this.state.abortController.signal
  }

  get isStoreLoaded(): boolean {
    return this.state.isStoreLoaded
  }

  get appVersion(): string | null {
    return this.state.appVersion
  }

  get holidays() {
    return this.state.holidays
  }

  get timezoneList() {
    return this.state.timezoneList
  }

  get customFonts() {
    return this.state.customFonts
  }
}

class DashboardMutations extends Mutations<DashboardState> {
  toggleSubSidebar() {
    this.state.isSubSidebarOpen = !this.state.isSubSidebarOpen
  }

  showSubSidebar() {
    this.state.isSubSidebarOpen = true
  }

  hideSubSidebar() {
    this.state.isSubSidebarOpen = false
  }

  toggleMainMenu() {
    this.state.isMainMenuHidden = !this.state.isMainMenuHidden
  }

  hideMainMenu() {
    this.state.isMainMenuHidden = true
  }

  setGooglePlacesLoaded() {
    this.state.isGooglePlacesLoaded = true
  }

  setGooglePlacesReady() {
    this.state.isGooglePlacesReady = true
  }

  setLocale(locale: LocaleSettings) {
    this.state.settings.locale = locale
    localDashboardStorage.state = this.state.settings
  }

  setTimezone(timezone: string) {
    this.state.settings.timezone = timezone
    localDashboardStorage.state = this.state.settings
  }

  abortRequests() {
    this.state.abortController.abort()
    this.state.abortController = new AbortController()
  }

  setIsStoreLoaded(state: boolean) {
    this.state.isStoreLoaded = state
  }

  setAppVersion(version: string) {
    this.state.appVersion = version
  }

  setCountryLocale(locale: CommonCountryResponseDto) {
    this.state.countryLocale = locale
  }

  setHolidays(holidays: NationalHolidayDataDto[]) {
    this.state.holidays = holidays
  }

  setTimezoneList(timezoneList: SchedulerTimezonesDto[]) {
    this.state.timezoneList = timezoneList
  }

  setCustomFonts(customFonts: Font[]) {
    this.state.customFonts = customFonts
  }
}

class DashboardActions extends Actions<
  DashboardState,
  DashboardGetters,
  DashboardMutations,
  DashboardActions
> {
  $init(): void {
    this.dispatch('_init')
  }

  _init() {
    this.commit('setIsStoreLoaded', false)

    if (
      null !== localDashboardStorage.state &&
      localDashboardStorage.isStateValid(localDashboardStorage.state)
    ) {
      this.dispatch('setSettings', localDashboardStorage.state)
    } else {
      localDashboardStorage.state = new PersistedDashboardState()
    }

    this.commit('setIsStoreLoaded', true)
  }

  setSettings(settings: PersistedDashboardState) {
    this.commit('setLocale', settings.locale)
    this.commit('setTimezone', settings.timezone)
  }

  async setLocale(locale: LocaleSettings) {
    this.commit('setLocale', locale)
    await setLocale(locale)
  }

  setTimezone(timezone: string) {
    this.commit('setTimezone', timezone)
  }

  toggleSubSidebar() {
    this.mutations.toggleSubSidebar()
    this.mutations.hideMainMenu()
  }

  toggleMainMenu() {
    this.mutations.toggleMainMenu()
  }

  showSubSidebar() {
    this.mutations.showSubSidebar()
  }

  hideSubSidebar() {
    this.mutations.hideSubSidebar()
  }

  abortRequests() {
    this.mutations.abortRequests()
  }

  setAppVersion(version: string) {
    this.commit('setAppVersion', version)
  }

  initGooglePlaces() {
    this.commit('setGooglePlacesReady')
  }

  async initNationalHolidays() {
    const resp: GetNationalHolidaysResponseDto = await getNationalHolidays()
    this.commit('setHolidays', resp.data.data)
  }

  async setTimezoneList(list: SchedulerTimezonesDto[]) {
    this.commit('setTimezoneList', list)
  }

  setCountryLocale(locale: CommonCountryResponseDto) {
    const [language, country] = locale.locale.split('_')
    this.commit('setLocale', <LocaleSettings>{
      language,
      country
    })
    this.commit('setCountryLocale', locale)
  }

  loadGooglePlaces() {
    if (this.getters.isGooglePlacesLoaded) {
      return
    }
    const apiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY
    if (!apiKey) {
      return
    }

    this.commit('setGooglePlacesLoaded')

    const callbackFn = 'initGooglePlaces'
    window[callbackFn] = this.actions[callbackFn]

    const scriptEl = document.createElement('script')
    scriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=${callbackFn}&loading=async`
    scriptEl.async = true
    document.head.append(scriptEl)
  }

  initCustomFonts() {
    const fetcher = (name: string) =>
      fetch(
        `${window.location.origin + import.meta.env.BASE_URL}pspdfkit/customFonts/${name}`
      ).then((r) => {
        if (r.status === 200) {
          return r.blob()
        } else {
          throw new Error()
        }
      })

    const customFonts = [
      // Uncomment once PSPDFKit gives an explanation why these font don't work
      // 'centurygothic.ttf',
      // 'comicsans.ttf',
      // 'dejavusans.ttf',
      // 'dejavuserif.ttf',
      // 'gillsans.otf',
      // 'lucidasans.ttf',
      // 'myriadpro.otf',
      // 'opensans.ttf',
      // 'palatino.ttf',
      'trebuchet.ttf',
      'zapfino.ttf'
    ].map((font) => {
      return new PSPDFKit.Font({ name: font, callback: fetcher })
    })

    this.commit('setCustomFonts', customFonts)
  }
}

export const dashboard = new Module({
  state: DashboardState,
  getters: DashboardGetters,
  mutations: DashboardMutations,
  actions: DashboardActions
}) as RuntimeModule<DashboardState, DashboardGetters, DashboardMutations, DashboardActions>

dashboard.namespace = 'DashboardModule/'
dashboard.path = ['DashboardModule']

export const useDashboard = createComposable(dashboard)
