export default {
  USERS: {
    SETTINGS: {
      INDEX: {
        PATH: '/users/:userId',
        NAME: 'users'
      },
      CONTACTS: {
        PATH: 'contacts',
        NAME: 'user.contacts'
      },
      PASSWORD: {
        PATH: 'password',
        NAME: 'user.password'
      },
      INTEGRATIONS: {
        INDEX:{
          PATH: 'integrations',
          NAME: 'user.integrations.index'
        },
        SETUP:{
          PATH: 'integrations/setup',
          NAME: 'user.integrations.setup'
        },
        GOOGLE:{
          PATH: 'integrations/google',
          NAME: 'user.integrations.google'
        },
        MICROSOFT:{
          PATH: 'integrations/microsoft',
          NAME: 'user.integrations.microsoft'
        },
        ZOOM:{
          PATH: 'integrations/zoom',
          NAME: 'user.integrations.zoom'
        },
        FACEBOOK:{
          PATH: 'integrations/facebook',
          NAME: 'user.integrations.facebook'
        },
      },
      PREFERENCES: {
        PATH: 'preferences',
        NAME: 'user.preferences'
      }
    },
    PROFILE: {
      INDEX: {
        PATH: '/b/:businessSlug/users/:userId',
        NAME: 'user.profile'
      },
      EDIT: {
        PATH: 'profile',
        NAME: 'user.profile.edit'
      },
      NOTIFICATIONS: {
        PATH: 'notifications',
        NAME: 'user.profile.notifications'
      }
    }
  }
}
