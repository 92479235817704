export default {
  PUBLIC: {
    UNSUBSCRIBE: {
      PATH: '/unsubscribe',
      NAME: 'public.unsubscribe'
    },
    SCHEDULER: {
      PATH: '/e/:dataFieldId/:leadId', // + ?signature=xxx
      NAME: 'public-scheduler'
    },
    INTELLIFORMS: {
      REGISTER: {
        PATH: '/p/:packageId',
        NAME: 'intelliforms.sign.register'
      },
      START: {
        PATH: '/f/:packageId',
        NAME: 'intelliforms.sign.start'
      },
      DOCUMENT: {
        PATH: '/f/:packageId/:formId',
        NAME: 'intelliforms.sign.document'
      },
      PAYMENT: {
        PATH: '/f/:packageId/payment',
        NAME: 'intelliforms.sign.payment'
      },
      EXPIRED: {
        PATH: '/f/:leadPackageId/expired',
        NAME: 'intelliforms.sign.expired'
      },
      NOT_FOUND: {
        PATH: '/:pathMatch(.*)*',
        NAME: 'intelliforms.not-found'
      }
    },
    INTERCEPT: {
      PROCARE_ONLINE_INTEGRATION: {
        PATH: '/i/pco/:siteId',
        NAME: 'intercept.procare-online-integration'
      }
    }
  }
}
