import { RouteList } from '@/routes/RouteList'
import { RouteRecordRaw } from 'vue-router'
import UIComponentView from '@/views/UI/UIComponentView.vue'

export default <RouteRecordRaw[]>[
  {
    path: RouteList.UI.INDEX.PATH,
    name: RouteList.UI.INDEX.NAME,
    component: UIComponentView
  }
]
