import authRoutesList from './RoutesLists/authRoutesList'
import lmsRoutesList from './RoutesLists/lmsRoutesList'
import emsRoutesList from './RoutesLists/emsRoutesList'
import marketplaceRoutesList from './RoutesLists/marketplaceRoutesList'
import websiteRoutesList from './RoutesLists/websiteRoutesList'
import settingsRoutesList from './RoutesLists/settingsRoutesList'
import usersRoutesList from './RoutesLists/usersRoutesList'
import miscRoutesList from './RoutesLists/miscRoutesList'
import backOfficeRoutesList from './RoutesLists/backOfficeRoutesList'
import publicRoutesList from './RoutesLists/publicRoutesList'
import taskRoutesList from '@/routes/RoutesLists/taskRoutesList'
import learningRoutesList from '@/routes/RoutesLists/learningRoutesList'
import uiRoutesList from '@/routes/RoutesLists/uiRoutesList'

export const RouteList = {
  INDEX: {
    PATH: '/',
    NAME: 'index'
  },
  ...authRoutesList,
  ...lmsRoutesList,
  ...emsRoutesList,
  ...marketplaceRoutesList,
  ...websiteRoutesList,
  ...settingsRoutesList,
  ...usersRoutesList,
  ...miscRoutesList,
  ...backOfficeRoutesList,
  ...taskRoutesList,
  ...learningRoutesList,
  ...publicRoutesList,
  ...uiRoutesList
}
