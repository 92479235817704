import { createLogger } from 'vuex'
import { createStore, hotUpdate } from 'vuex-smart-module'
import RootModule from './root'

const debug = process.env.NODE_ENV !== 'production'

const store = createStore(RootModule, {
  strict: debug,
  plugins: debug ? [createLogger()] : []
})

if (import.meta.hot) {
  import.meta.hot.accept(['./root'], () => {
    hotUpdate(store, RootModule)
  })
}
// TODO maybe migrate to https://pinia.vuejs.org ?

export default store
