<template>
  <a-tooltip
    v-if="tooltipText"
    :destroy-tooltip-on-hide="true"
    :get-popup-container="getPopupContainer"
    :placement="tooltipPlacement"
  >
    <template v-if="tooltipText.length" #title>
      <span v-if="isCopyEnabled" @click="copyToClipboard(String(tooltipText))">
        <div class="d-flex flex-column">
          <span>{{ t('Click to Copy:') }}</span>
          <div class="btn btn-link text-decoration-none text-white p-0 m-0">{{ tooltipText }}</div>
        </div>
      </span>
      <span v-else>{{ tooltipText }}</span>
    </template>
    <div
      :class="`${color ? 'text-white bg-' + getColorSuffix(color, border) : borderClass} ${
        $style['status-badge']
      } ${$style['status-badge-' + size]} ${square ? 'rounded' : 'rounded-circle'} ${
        border
          ? `border ${size === 'xs' ? 'border-1' : 'border-2'} border-${getColorSuffix(
              color,
              false
            )} text-${getColorSuffix(color, false)}`
          : ''
      }`"
      role="button"
      class="user-select-none overflow-hidden"
      @click="copyToClipboard(String(tooltipText))"
    >
      <span :class="labelClass" :data-test-id="`lead-badge__${formattedLabel}`">
        {{ label || '?' }}
      </span>
    </div>
  </a-tooltip>
  <div
    v-else
    :class="`${color ? 'bg-' + getColorSuffix(color, border) : borderClass} ${
      $style['status-badge']
    }
    ${$style['status-badge-' + size]} ${square ? 'rounded' : 'rounded-circle'} ${
      border
        ? `border ${size === 'xs' ? 'border-1' : 'border-2'} border-${getColorSuffix(
            color,
            false
          )} text-${getColorSuffix(color, false)}`
        : ''
    }`"
    class="user-select-none overflow-hidden"
  >
    <span :data-test-id="`lead-badge__${formattedLabel}`">{{ label || '?' }}</span>
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { getColorSuffix } from '@/helpers/status.helper'
import { notifySuccess } from '@/helpers/notification.helper'
import { BadgeSize, LeadStatusColor } from '@/shared'
import { asyncComponentWrapper } from '@/composables/useReloadModal'

const ATooltip = asyncComponentWrapper(() => import('ant-design-vue/es/tooltip'))

const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  labelClass: {
    type: String,
    default: ''
  },
  tooltipText: {
    type: String,
    default: ''
  },
  tooltipPlacement: {
    type: String as PropType<'left' | 'top' | 'right' | 'bottom'>,
    default: 'top'
  },
  color: {
    type: String as PropType<LeadStatusColor>,
    default: ''
  },
  parentContainer: {
    type: Boolean,
    default: false
  },
  square: {
    type: Boolean,
    default: true
  },
  border: {
    type: Boolean,
    default: false
  },
  borderClass: {
    type: String,
    default: 'border border-2'
  },
  size: {
    type: String as PropType<BadgeSize>,
    default: 'md'
  },
  isCopyEnabled: {
    type: Boolean,
    default: false
  }
})

const { t } = useI18n()

const formattedLabel = computed(() => {
  return props.label?.replace(' ', '-').toLowerCase()
})

const getPopupContainer = (triggerNode: HTMLElement) =>
  props.parentContainer ? <HTMLElement>triggerNode.parentNode : document.body

const copyToClipboard = async (tooltipText: string) => {
  if (!props.isCopyEnabled) {
    return
  }

  try {
    await navigator.clipboard.writeText(tooltipText)
    notifySuccess(t('Copied to clipboard'))
  } catch {}
}
</script>

<style lang="scss" module>
.status-badge {
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 992px) {
    width: 2rem;
    min-width: 2rem;
    height: 2rem;
    min-height: 2rem;
  }

  > span {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.2;
    text-align: center;

    @media (max-width: 992px) {
      font-size: 12px;
    }
  }
}

.status-badge-lg {
  width: 2.5rem;
  height: 2.5rem;
}

.status-badge-sm {
  width: 1.5rem;
  height: 1.5rem;
  min-width: 1.5rem;
  min-height: 1.5rem;
  padding: 0.75rem;

  > span {
    font-size: 0.625rem;
  }
}

.status-badge-xs {
  width: 1rem;
  height: 1rem;
  min-width: 1rem;
  min-height: 1rem;
  padding: 0.55rem;

  > span {
    font-size: 0.5rem;
  }
}
</style>
