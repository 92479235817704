import { RouteRecordRaw } from 'vue-router'
import { RouteList } from '@/routes/RouteList'

const GuestNavBar = () => import('@/components/Layout/GuestNavBar.vue')
const RegistrationNavBar = () =>
  import('@/components/Auth/Registration/Components/RegistrationNavBar.vue')
const LoginForm = () => import('@/components/Auth/LoginForm.vue')
const ForgotPasswordForm = () =>
  import('@/components/Auth/ForgotPasswordForm.vue')
const ResetPasswordForm = () =>
  import('@/components/Auth/ResetPasswordForm.vue')
const AcceptUserInvitationForm = () =>
  import('@/components/Auth/Invitations/AcceptUserInvitationForm.vue')
const AcceptAdminInvitationForm = () =>
  import('@/components/Auth/Invitations/AcceptAdminInvitationForm.vue')
const ChooseBusinessForm = () =>
  import('@/components/Auth/ChooseBusinessForm.vue')
const RegisterUserForm = () =>
  import('@/components/Auth/Registration/RegisterUserForm.vue')
const RegisterBusinessForm = () =>
  import('@/components/Auth/Registration/RegisterBusinessForm.vue')
const RegisterCompanyForm = () =>
  import('@/components/Auth/Registration/RegisterCompanyForm.vue')
const RegisterLocationsForm = () =>
  import('@/components/Auth/Registration/RegisterLocationsForm.vue')
const RegisterPaymentForm = () =>
  import('@/components/Auth/Registration/RegisterPaymentForm.vue')
const RegisterTermsForm = () =>
  import('@/components/Auth/Registration/RegisterTermsForm.vue')

export default <RouteRecordRaw[]>[
  {
    path: RouteList.AUTH.LOGIN.PATH,
    name: RouteList.AUTH.LOGIN.NAME,
    components: {
      NavBar: GuestNavBar,
      default: LoginForm
    },
    meta: {
      requiresAuth: false,
      requiresSubscription: false
    }
  },
  {
    name: RouteList.AUTH.PASSWORD.FORGOT_PASSWORD.NAME,
    path: RouteList.AUTH.PASSWORD.FORGOT_PASSWORD.PATH,
    components: {
      NavBar: GuestNavBar,
      default: ForgotPasswordForm
    },
    meta: {
      requiresAuth: false,
      requiresSubscription: false
    }
  },
  {
    name: RouteList.AUTH.PASSWORD.RESET_PASSWORD.NAME,
    path: RouteList.AUTH.PASSWORD.RESET_PASSWORD.PATH,
    components: {
      NavBar: GuestNavBar,
      default: ResetPasswordForm
    },
    meta: {
      requiresAuth: false,
      requiresSubscription: false
    }
  },
  {
    path: RouteList.AUTH.INVITATIONS.USER.PATH,
    name: RouteList.AUTH.INVITATIONS.USER.NAME,
    components: {
      NavBar: GuestNavBar,
      default: AcceptUserInvitationForm
    },
    meta: {
      requiresAuth: false,
      requiresSubscription: false
    }
  },
  {
    path: RouteList.AUTH.INVITATIONS.ADMIN.PATH,
    name: RouteList.AUTH.INVITATIONS.ADMIN.NAME,
    components: {
      NavBar: GuestNavBar,
      default: AcceptAdminInvitationForm
    },
    meta: {
      requiresAuth: false,
      requiresSubscription: false
    }
  },
  {
    path: RouteList.AUTH.BUSINESS_CHOICE.PATH,
    name: RouteList.AUTH.BUSINESS_CHOICE.NAME,
    components: {
      NavBar: GuestNavBar,
      default: ChooseBusinessForm
    },
    meta: {
      requiresAuth: true,
      requiresSubscription: false
    }
  },
  {
    path: RouteList.AUTH.REGISTRATION.STEPS.USER.PATH,
    name: RouteList.AUTH.REGISTRATION.STEPS.USER.NAME,
    components: {
      NavBar: GuestNavBar,
      default: RegisterUserForm
    },
    meta: {
      requiresAuth: false,
      requiresSubscription: false
    }
  },
  {
    path: RouteList.AUTH.REGISTRATION.STEPS.BUSINESS.PATH,
    name: RouteList.AUTH.REGISTRATION.STEPS.BUSINESS.NAME,
    components: {
      NavBar: RegistrationNavBar,
      default: RegisterBusinessForm
    },
    meta: {
      requiresAuth: true,
      requiresSubscription: false
    }
  },
  {
    path: RouteList.AUTH.REGISTRATION.STEPS.LOCATIONS.PATH,
    name: RouteList.AUTH.REGISTRATION.STEPS.LOCATIONS.NAME,
    components: {
      NavBar: RegistrationNavBar,
      default: RegisterLocationsForm
    },
    meta: {
      requiresAuth: true,
      requiresSubscription: false
    },
    beforeEnter: (to, from, next) => {
      if (
        from.name === RouteList.AUTH.REGISTRATION.STEPS.BUSINESS.NAME ||
        from.name === RouteList.AUTH.REGISTRATION.STEPS.COMPANY.NAME
      ) {
        return next()
      }

      return next(RouteList.AUTH.REGISTRATION.STEPS.BUSINESS.PATH)
    }
  },
  {
    path: RouteList.AUTH.REGISTRATION.STEPS.COMPANY.PATH,
    name: RouteList.AUTH.REGISTRATION.STEPS.COMPANY.NAME,
    components: {
      NavBar: RegistrationNavBar,
      default: RegisterCompanyForm
    },
    meta: {
      requiresAuth: true,
      requiresSubscription: false
    },
    beforeEnter: (to, from, next) => {
      if (
        from.name === RouteList.AUTH.REGISTRATION.STEPS.LOCATIONS.NAME ||
        from.name === RouteList.AUTH.REGISTRATION.STEPS.TERMS.NAME
      ) {
        return next()
      }
      return next(RouteList.AUTH.REGISTRATION.STEPS.BUSINESS.PATH)
    }
  },
  {
    path: RouteList.AUTH.REGISTRATION.STEPS.TERMS.PATH,
    name: RouteList.AUTH.REGISTRATION.STEPS.TERMS.NAME,
    components: {
      NavBar: RegistrationNavBar,
      default: RegisterTermsForm
    },
    meta: {
      requiresAuth: true,
      requiresSubscription: false
    },
    beforeEnter: (to, from, next) => {
      if (
        from.name === RouteList.AUTH.REGISTRATION.STEPS.COMPANY.NAME ||
        from.name === RouteList.AUTH.REGISTRATION.STEPS.PAYMENT.NAME
      ) {
        return next()
      }

      return next(RouteList.AUTH.REGISTRATION.STEPS.BUSINESS.PATH)
    }
  },
  {
    path: RouteList.AUTH.REGISTRATION.STEPS.PAYMENT.PATH,
    name: RouteList.AUTH.REGISTRATION.STEPS.PAYMENT.NAME,
    components: {
      NavBar: RegistrationNavBar,
      default: RegisterPaymentForm
    },
    meta: {
      requiresAuth: true,
      requiresSubscription: false
    }
  }
]
