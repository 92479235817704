<template>
  <slot :collapse-id="collapseId"></slot>
</template>

<script lang="ts" setup>
import uid from '@/helpers/uid'
import { onBeforeUnmount, onMounted, toRef, watch } from 'vue'
import Collapse from 'bootstrap/js/dist/collapse'

const collapseId = `bs-collapse-${uid.seq()}`
let collapse: Collapse | null = null
let collapseEl: Element | null = null

const props = defineProps({
  collapsed: {
    type: Boolean,
    default: false
  }
})

const isCollapsed = toRef(props, 'collapsed')

const emits = defineEmits<{
  (e: 'show'): void
  (e: 'hide'): void
  (e: 'shown'): void
  (e: 'hidden'): void
}>()

const onShow = () => emits('show')
const onHide = () => emits('hide')
const onShown = () => emits('shown')
const onHidden = () => emits('hidden')

watch(isCollapsed, (newVal: boolean) => {
  newVal
    ? collapse?.show()
    : collapse?.hide()
})

onMounted(() => {
  collapseEl = document.getElementById(collapseId)

  if (null !== collapseEl) {
    collapse = new Collapse(collapseEl, {
      toggle: isCollapsed.value
    })

    collapseEl.addEventListener('show.bs.collapse', onShow)
    collapseEl.addEventListener('hide.bs.collapse', onHide)
    collapseEl.addEventListener('shown.bs.collapse', onShown)
    collapseEl.addEventListener('hidden.bs.collapse', onHidden)
  }
})

onBeforeUnmount(() => {
  collapseEl?.removeEventListener('show.bs.collapse', onShow)
  collapseEl?.removeEventListener('hide.bs.collapse', onHide)
  collapseEl?.removeEventListener('shown.bs.collapse', onShown)
  collapseEl?.removeEventListener('hidden.bs.collapse', onHidden)
  collapse?.dispose()
})
</script>
