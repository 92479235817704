export type AbilityResponse = {
  id: string
  name: string
  title: string
  label: string
  description: string
  group: string
  level: number
  entity_id: string | null
  entity_type: string | null
  children: AbilityResponse[]
}

export interface TransformedAbility extends PermissionDto {
  loading: boolean
  allow: boolean
  children: TransformedAbility[]
}

export default class PermissionDto {
  public id: string
  public name: string
  public group: string
  public title: string
  public label: string
  public description: string
  public level: number
  public entity_id: string | null
  public entity_type: string | null
  public children: PermissionDto[]

  constructor(
    id: string,
    name: string,
    group: string,
    title: string,
    label: string,
    description: string,
    level: number,
    entityId: string | null,
    entityType: string | null,
    children: PermissionDto[] = []
  ) {
    this.id = id
    this.name = name
    this.group = group
    this.title = title
    this.label = label
    this.description = description
    this.level = level
    this.entity_id = entityId
    this.entity_type = entityType
    this.children = children
  }
}

export function transformBusinessUserAbilityResponseToDto(
  businessUserAbility: AbilityResponse
): PermissionDto {
  return new PermissionDto(
    businessUserAbility.id,
    businessUserAbility.name,
    businessUserAbility.group,
    businessUserAbility.title,
    businessUserAbility.label,
    businessUserAbility.description,
    businessUserAbility.level,
    businessUserAbility.entity_id,
    businessUserAbility.entity_type,
    businessUserAbility.children
  )
}
