import { RouteRecordRaw } from 'vue-router'
import { RouteList } from '@/routes/RouteList'

const ProcareOnlineIntegrationRedirectView = () =>
  import('@/views/Public/Intercept/ProcareOnlineIntegrationRedirectView.vue')

export default <RouteRecordRaw[]>[
  {
    path: RouteList.PUBLIC.INTERCEPT.PROCARE_ONLINE_INTEGRATION.PATH,
    name: RouteList.PUBLIC.INTERCEPT.PROCARE_ONLINE_INTEGRATION.NAME,
    component: ProcareOnlineIntegrationRedirectView,
    meta: {
      requiresAuth: true
    }
  }
]
