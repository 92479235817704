export default {
  BACK_OFFICE: {
    INDEX: {
      PATH: '/admin',
      NAME: 'back-office.index'
    },
    BUSINESSES: {
      INDEX: {
        PATH: 'businesses',
        NAME: 'back-office.businesses.index'
      },
      BUSINESS: {
        INDEX: {
          PATH: ':businessSlug',
          NAME: 'back-office.businesses.business'
        },
        PROFILE: {
          PATH: 'profile',
          NAME: 'back-office.businesses.business.profile'
        },
        COMPANY: {
          PATH: 'company',
          NAME: 'back-office.businesses.business.company'
        },
        BALANCE: {
          PATH: 'balance',
          NAME: 'back-office.businesses.business.balance'
        },
        OTHER: {
          PATH: 'other',
          NAME: 'back-office.businesses.business.other'
        }
      }
    },
    USERS: {
      PATH: 'users',
      NAME: 'back-office.users'
    },
    ADMINS: {
      PATH: 'admins',
      NAME: 'back-office.admins'
    },
    ROLES: {
      PATH: 'roles',
      NAME: 'back-office.roles'
    },
    SUBSCRIPTIONS: {
      INDEX: {
        PATH: 'subscriptions',
        NAME: 'back-office.subscriptions'
      },
      SINGLE: {
        PATH: 'subscriptions/:subscriptionId',
        NAME: 'back-office.subscriptions.single'
      }
    },
    SERVICES: {
      PATH: 'services',
      NAME: 'back-office.services'
    },
    FEATURES: {
      PATH: 'features',
      NAME: 'back-office.features'
    },
    INSTALLATION: {
      PATH: 'installation-package',
      NAME: 'back-office.installation'
    },
    CONFIGURATION: {
      PATH: 'configuration',
      NAME: 'back-office.configuration'
    },
    EMAIL: {
      THEMES: {
        PATH: 'email/themes',
        NAME: 'back-office.email.themes'
      },
      DESIGNS: {
        PATH: 'email/designs',
        NAME: 'back-office.email.designs'
      },
      NAME: 'back-office.business.index'
    },
    LOGS: {
      PATH: 'logs-jobs',
      NAME: 'back-office.logs'
    },
    INTEGRATIONS: {
      INDEX: {
        PATH: 'integrations',
        NAME: 'back-office.integrations.index'
      },
      TWILIO: {
        PATH: 'twilio',
        NAME: 'back-office.integrations.twilio'
      },
      MAILGUN: {
        PATH: 'mailgun',
        NAME: 'back-office.integrations.mailgun'
      },
      INTERCOM: {
        PATH: 'intercom',
        NAME: 'back-office.integrations.intercom'
      },
      GOOGLE: {
        PATH: 'google',
        NAME: 'back-office.integrations.google'
      },
      BEEFREE: {
        PATH: 'beefree',
        NAME: 'back-office.integrations.beefree'
      },
      FACEBOOK: {
        PATH: 'facebook',
        NAME: 'back-office.integrations.facebook'
      },
      MICROSOFT: {
        PATH: 'microsoft',
        NAME: 'back-office.integrations.microsoft'
      },
      ZOOM: {
        PATH: 'zoom',
        NAME: 'back-office.integrations.zoom'
      },
      LOOKER_STUDIO: {
        PATH: 'looker-studio',
        NAME: 'back-office.integrations.looker-studio'
      },
      PROCARE: {
        PATH: 'procare',
        NAME: 'back-office.integrations.procare'
      },
      KANGAROOTIME: {
        PATH: 'kangarootime',
        NAME: 'back-office.integrations.kangarootime'
      },
      FINIX: {
        PATH: 'finix',
        NAME: 'back-office.integrations.finix'
      },
      CHECKR: {
        PATH: 'checkr',
        NAME: 'back-office.integrations.checkr'
      },
      INDEED: {
        PATH: 'indeed',
        NAME: 'back-office.integrations.indeed'
      },
      CARE: {
        PATH: 'care',
        NAME: 'back-office.integrations.care'
      },
      PSPDFKIT: {
        PATH: 'pspdfkit',
        NAME: 'back-office.integrations.pspdfkit'
      },
      MIGRATION: {
        INDEX: {
          PATH: 'migration',
          NAME: 'back-office.integrations.migration'
        },
        BUSINESSES: {
          PATH: 'businesses',
          NAME: 'back-office.integrations.migration.businesses'
        },
        LOCATIONS: {
          PATH: 'locations',
          NAME: 'back-office.integrations.migration.locations'
        },
        USERS: {
          PATH: 'users',
          NAME: 'back-office.integrations.migration.users'
        },
        OTHER: {
          PATH: 'other',
          NAME: 'back-office.integrations.migration.other'
        }
      }
    },
    USER_SETTINGS: {
      INDEX: {
        PATH: '/admin/user/:userId',
        NAME: 'back-office.user'
      },
      PROFILE: {
        PATH: 'profile',
        NAME: 'back-office.user.profile'
      },
      NOTIFICATIONS: {
        PATH: 'notifications',
        NAME: 'back-office.user.profile.notifications'
      },
      CONTACTS: {
        PATH: 'contacts',
        NAME: 'back-office.users.user.contacts'
      },
      PASSWORD: {
        PATH: 'password',
        NAME: 'back-office.users.user.password'
      },
      INTEGRATIONS: {
        INDEX: {
          PATH: 'integrations',
          NAME: 'back-office.users.user.integrations.index'
        },
        SETUP: {
          PATH: 'integrations/setup',
          NAME: 'back-office.users.user.integrations.setup'
        },
        GOOGLE: {
          PATH: 'integrations/google',
          NAME: 'back-office.users.user.integrations.google'
        },
        MICROSOFT: {
          PATH: 'integrations/microsoft',
          NAME: 'back-office.users.user.integrations.microsoft'
        },
        ZOOM: {
          PATH: 'integrations/zoom',
          NAME: 'back-office.users.user.integrations.zoom'
        },
        FACEBOOK: {
          PATH: 'integrations/facebook',
          NAME: 'back-office.users.user.integrations.facebook'
        },
      },
      PREFERENCES: {
        PATH: 'preferences',
        NAME: 'back-office.users.user.preferences'
      }
    }
  }
}
