export default {
  TASKS: {
    TASKS: {
      INDEX: {
        PATH: '/b/:businessSlug/tasks',
        NAME: 'tasks'
      },
      ALL: {
        PATH: '/b/:businessSlug/tasks/all',
        NAME: 'tasks.all'
      },
      TASK: {
        PATH: '/b/:businessSlug/tasks/task/:taskId',
        NAME: 'tasks.task'
      },
      MY_TASKS: {
        PATH: '/b/:businessSlug/tasks/my-tasks',
        NAME: 'tasks.my-tasks'
      },
      ACTIONS_TO_TAKE: {
        PATH: '/b/:businessSlug/tasks/actions-to-take',
        NAME: 'tasks.actions-to-take'
      },
      WITHOUT_ASSIGNEE: {
        PATH: '/b/:businessSlug/tasks/without-assignee',
        NAME: 'tasks.without-assignee'
      },
      CREATED_BY_ME: {
        PATH: '/b/:businessSlug/tasks/created-by-me',
        NAME: 'tasks.created-by-me'
      },
      ARCHIVE: {
        PATH: '/b/:businessSlug/tasks/archive',
        NAME: 'tasks.archive'
      }
    },
    TASK_TYPES: {
      INDEX: {
        PATH: '/b/:businessSlug/task/types',
        NAME: 'task-types.index'
      }
    },
    TASKS_TEMPLATES: {
      INDEX: {
        PATH: '/b/:businessSlug/task/templates',
        NAME: 'task-templates.index'
      },
      BUSINESS: {
        PATH: '/b/:businessSlug/task/templates/business',
        NAME: 'task-templates.business'
      },
      LMS: {
        PATH: '/b/:businessSlug/task/templates/lms',
        NAME: 'task-templates.lms'
      },
      EMS: {
        PATH: '/b/:businessSlug/task/templates/ems',
        NAME: 'task-templates.ems'
      }
    }
  }
}
