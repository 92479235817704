<template>
  <template v-if="hasPermission">
    <slot></slot>
  </template>
  <a-tooltip v-else-if="!hide" :title="t(`You don't have permission.`)" placement="bottom">
    <div class="tooltip-disabled" :class="wrapperClass">
      <slot />
    </div>
  </a-tooltip>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import usePermissions from '@/composables/usePermissions'
import { asyncComponentWrapper } from '@/composables/useReloadModal'

const ATooltip = asyncComponentWrapper(() => import('ant-design-vue/es/tooltip'))

const props = defineProps({
  permission: {
    type: [String, Array],
    required: true
  },
  wrapperClass: { type: String, default: 'd-grid' },
  hide: { type: Boolean, default: false },
  admin: { type: Boolean, default: false }
})

const { t } = useI18n()
const { permissionExists } = usePermissions()

const hasPermission = computed(() =>
  permissionExists(
    typeof props.permission === 'string' ? props.permission : (props.permission as string[]),
    props.admin
  )
)
</script>

<style lang="scss">
.tooltip-disabled {
  cursor: not-allowed !important;

  a:not(.btn) {
    color: #adb5bd;
    background-color: transparent;
    pointer-events: none;
  }

  .btn,
  button {
    opacity: 0.65;
    pointer-events: none;
  }
}
</style>
