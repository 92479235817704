import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios'
import axiosRetry from 'axios-retry'
import store from '@/store'
import router from '@/routes'
import { REFRESH_TOKEN_URL, REVOKE_TOKEN_URL } from '@/api/Auth/urlConst'
import { HttpStatusCodes, serverTimeoutErrorsList } from '@/enums/HttpStatusCodes'
import { RouteList } from '@/routes/RouteList'
import { notifyError } from '@/helpers/notification.helper'
import { BuildVersion } from '@/shared'

const NETWORK_ERROR_MESSAGE = 'Network Error'
const REQUEST_METHOD_GET = 'get'
const AXIOS_TIMEOUT = 'ECONNABORTED'

const client = axios.create({
  baseURL: import.meta.env.VITE_CORE_BASE_URL,
  timeout: import.meta.env.VITE_REQUEST_TIMEOUT || 30000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  }
})

// Disable retries in any environment except main and production if VITE_DISABLE_API_RETRY=true
// This was asked by some BE developers
const disableRetries =
  !import.meta.env.VITE_BUILD_VERSION?.match(BuildVersion.Main || BuildVersion.Production) &&
  import.meta.env.VITE_DISABLE_API_RETRY

axiosRetry(client, {
  retries: disableRetries ? 0 : 2,
  retryCondition: (error: any) => {
    // Retry on undefined, network issue, axios timeout or server errors >= 500
    return (
      typeof error.response === 'undefined' ||
      NETWORK_ERROR_MESSAGE === error.message ||
      error.code === AXIOS_TIMEOUT ||
      (error.response?.status && serverTimeoutErrorsList.includes(error.response.status))
    )
  },
  shouldResetTimeout: true,
  retryDelay: axiosRetry.exponentialDelay
})

export interface AbortableAxiosRequestConfig extends InternalAxiosRequestConfig {
  _cannotAbort?: boolean
}

export interface NoErrorAxiosRequestConfig extends InternalAxiosRequestConfig {
  _noError?: boolean
}

interface RetryableAxiosRequestConfig extends AbortableAxiosRequestConfig {
  _isRetry?: boolean
}

client.interceptors.response.use(
  async (response) => {
    // save the newest app version in store, which comes from backend
    const version = response.headers['x-iks-app-version']

    if (version) {
      await store.dispatch('DashboardModule/setAppVersion', version)
    }

    return response
  },

  async (error) => {
    if (axios.isCancel(error)) {
      return Promise.reject(error)
    }

    const noErrorRequest: NoErrorAxiosRequestConfig = error.config
    const originalRequest: RetryableAxiosRequestConfig = error.config

    // Let's not show general errors if the request is unauthorized OR request payload could not be processes OR we do not want
    // to show and error for some request OR axios request timed out OR its server 5xx error
    if (
      axios.isAxiosError(error) &&
      HttpStatusCodes.Unauthorized !== error.response?.status &&
      HttpStatusCodes.UnprocessableEntity !== error.response?.status &&
      !noErrorRequest._noError &&
      error.code !== AXIOS_TIMEOUT &&
      error.response?.status &&
      !serverTimeoutErrorsList.includes(error.response.status)
    ) {
      let description = 'Unknown error'

      if (error.message) {
        description = error.message || description
      }

      if ((<AxiosError<{ message?: string }>>error).response?.data?.message) {
        description =
          (<AxiosError<{ message?: string }>>error).response?.data?.message || description
      }

      notifyError(description)
    }

    // If error is unknown or it is an axios timeout or 5xx server error, then check if the request was GET or POST
    if (
      axios.isAxiosError(error) &&
      (typeof error.response === 'undefined' ||
        NETWORK_ERROR_MESSAGE === error.message ||
        error.code === AXIOS_TIMEOUT ||
        (error.response?.status && serverTimeoutErrorsList.includes(error.response.status)))
    ) {
      // When the request is GET, let's try one more time to get the resources and if that
      // request returns the same server or other unknow error, redirect the user to 500 error page.
      if (error?.config?.method === REQUEST_METHOD_GET) {
        await router.push({
          name: RouteList.MISC.ERROR.NAME,
          params: { errorCode: 500 }
        })
      } else {
        // When the request is POST, just notify the user with the message and let him send another POST request
        notifyError("We've encountered a network issue. Please try again.")
      }

      return Promise.reject(error)
    }

    if (!axios.isAxiosError(error) || HttpStatusCodes.Unauthorized !== error.response?.status) {
      return Promise.reject(error)
    }

    if (originalRequest._isRetry || originalRequest.url?.includes(REVOKE_TOKEN_URL)) {
      return Promise.reject(error)
    }

    if (originalRequest.url?.includes(REFRESH_TOKEN_URL)) {
      // We dispatch auth logout action in store if db updates
      // and refresh token is no longer valid
      await store.dispatch('AuthModule/logout')
      await router.push({
        name: RouteList.AUTH.LOGIN.NAME
      })

      return Promise.reject(error)
    }

    try {
      originalRequest._isRetry = true

      await store.dispatch('AuthModule/refreshTokens')

      if (originalRequest.headers) {
        originalRequest.headers['Authorization'] =
          `Bearer ${store.getters['AuthModule/accessToken']}`
      }

      return client(originalRequest)
    } catch (e) {
      return Promise.reject(error)
    }
  }
)

client.interceptors.request.use(async (config: AbortableAxiosRequestConfig) => {
  if (store.getters['AuthModule/isAuthenticated']) {
    if (config.headers) {
      config.headers['Authorization'] = `Bearer ${store.getters['AuthModule/accessToken']}`
    }
  }

  // check if abort signal is not set manually and request can be aborted
  if (config.signal === undefined && !config._cannotAbort && config.method === REQUEST_METHOD_GET) {
    // set abort signal from store, to allow global access to cancel all requests
    config.signal = store.getters['DashboardModule/abortControllerSignal']
  }

  return config
})

export default client
