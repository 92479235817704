<template>
  <BaseFormFieldLayout
    :id="id"
    :label="label"
    :note="note"
    :error-message="errorMessage"
    :field-class="fieldClass"
  >
    <input
      :id="id"
      v-maska:unmaskedValue.unmasked="maskPattern"
      :value="model"
      v-bind="$attrs"
      class="form-control"
      :class="{ 'is-invalid': errorMessage }"
      @input="handleInput"
    />
  </BaseFormFieldLayout>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { vMaska } from 'maska/vue'

const model = defineModel<string | unknown>()

const unmaskedValue = ref('')

defineExpose({ unmaskedValue })

defineProps({
  id: {
    type: [String || Number],
    required: true
  },
  label: {
    type: String,
    default: ''
  },
  note: {
    type: String,
    default: ''
  },
  errorMessage: {
    type: String,
    default: ''
  },
  fieldClass: {
    type: String,
    default: ''
  },
  maskPattern: {
    type: String,
    default: ''
  },
})

const emit = defineEmits<{
  (e: 'input', value: string | undefined): void
}>()

const handleInput = () => {
  emit('input', unmaskedValue.value)
}
</script>
