<template>
  <div class="form-check form-check-inline">
    <input
      :id="`${name}-true`"
      v-model="value"
      :name="name"
      :value="true"
      class="form-check-input"
      :class="{
        'is-invalid': hasError
      }"
      type="radio"
    />
    <label class="form-check-label text-muted" :for="`${name}-true`">
      {{ t('Yes') }}
    </label>
  </div>
  <div class="form-check form-check-inline">
    <input
      :id="`${name}-false`"
      v-model="value"
      :name="name"
      :value="false"
      class="form-check-input"
      :class="{
        'is-invalid': hasError
      }"
      type="radio"
    />
    <label class="form-check-label text-muted" :for="`${name}-false`">
      {{ t('No') }}
    </label>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  modelValue: {
    type: Boolean,
    required: false
  },
  hasError: {
    required: false,
    type: Boolean,
    default: false
  }
})

const emits = defineEmits<(e: 'update:modelValue', value: boolean) => true>()

const value = computed({
  get: () => props.modelValue,
  set: (value) => emits('update:modelValue', value)
})

const { t } = useI18n()

</script>
