<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<!-- eslint-disable vue/no-v-html -->
<template>
  <component :is="as" v-if="sanitizedText" v-html="sanitizedText" />
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue'
import DOMPurify from 'dompurify'

const props = defineProps({
  text: {
    type: null as unknown as PropType<string | null>,
    required: true
  },
  as: {
    type: String,
    default: 'div'
  }
})

const sanitizedText = computed(() =>
  props.text === null ? null : DOMPurify.sanitize(props.text, { ADD_ATTR: ['target'] })
)
</script>
