import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { RouteList } from '@/routes/RouteList'
import { useBusiness } from '@/store/Business'
import PermissionDto from '@/dto/Auth/PermissionDto'
import { useAuth } from '@/store/Auth'
import { AbilityEnum } from '@/enums/AbilityEnum'

export const permissionExistsInPermissions = (
  requiredPermission: string | string[],
  admin = false,
  isUserAdmin = false,
  existingPermissionNames: string[] = []
) => {
  if (!Array.isArray(requiredPermission)) {
    requiredPermission = [requiredPermission]
  }

  if (admin) {
    return (
      existingPermissionNames.includes('*') ||
      requiredPermission.every((rp) => existingPermissionNames.includes(rp))
    )
  }

  return isUserAdmin || requiredPermission.every((rp) => existingPermissionNames.includes(rp))
}

export default () => {
  const businessState = useBusiness()
  const authState = useAuth()
  const route = useRoute()

  const userPermissions = computed(() =>
    authState.getters.abilities.map((ba: PermissionDto) => ba.name)
  )
  const businessUserPermissions = computed(() =>
    businessState.getters.abilities.map((ba: PermissionDto) => ba.name)
  )

  const permissions = computed(() => userPermissions.value.concat(businessUserPermissions.value))

  const leadLmsEmsPermissions = computed(() => {
    const lmsPermissions = [
      AbilityEnum.LMS_LEAD_CREATE,
      AbilityEnum.LMS_LEAD_UPDATE,
      AbilityEnum.LMS_LEAD_DELETE
    ]

    const emsPermissions = [
      AbilityEnum.EMS_LEAD_CREATE,
      AbilityEnum.EMS_LEAD_UPDATE,
      AbilityEnum.EMS_LEAD_DELETE
    ]

    const permissions = {
      [RouteList.LMS.LEADS.LEAD.NAME]: lmsPermissions,
      [RouteList.EMS.LEADS.LEAD.NAME]: emsPermissions,
      [RouteList.LMS.TOOLS.DUPLICATES.MERGE.NAME]: lmsPermissions,
      [RouteList.EMS.TOOLS.DUPLICATES.MERGE.NAME]: emsPermissions
    }

    return permissions[route.name as string] || []
  })

  const permissionExists = (requiredPermission: string | string[], admin = false) =>
    permissionExistsInPermissions(
      requiredPermission,
      admin,
      authState.getters.user?.isAdmin,
      permissions.value
    )

  const hasAllLocationAccess = computed<boolean>(() => {
    // if user is admin or has ability for all locations access
    if (authState.getters.user?.isAdmin) {
      return true
    }

    return !!businessState.getters.abilities.find(
      (l) => l.entity_id === null && l.name === AbilityEnum.LOCATION_ACCESS
    )
  })

  return {
    permissions,
    leadLmsEmsPermissions,
    permissionExists,
    hasAllLocationAccess
  }
}
