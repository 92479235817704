import Repository from '@/store/repository'

export class PersistedBusinessState {
  slug: string | null = null
}

export default class BusinessRepository extends Repository<PersistedBusinessState> {
  private static localInstance?: BusinessRepository
  private static sessionInstance?: BusinessRepository

  protected get storageKey(): string {
    return 'business_state'
  }

  public static getSessionInstance(): BusinessRepository {
    if (undefined === BusinessRepository.sessionInstance) {
      BusinessRepository.sessionInstance = new BusinessRepository(
        window.sessionStorage
      )
    }
    return BusinessRepository.sessionInstance
  }

  public static getLocalInstance(): BusinessRepository {
    if (undefined === BusinessRepository.localInstance) {
      BusinessRepository.localInstance = new BusinessRepository(
        window.localStorage
      )
    }

    return BusinessRepository.localInstance
  }

  public isStateValid(persistedBusinessState: PersistedBusinessState | null) {
    return null !== persistedBusinessState && null !== persistedBusinessState.slug
  }
}
