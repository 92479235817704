<template>
  <div>
    <input
      v-bind="$attrs"
      v-model="formattedEmail"
      :placeholder="placeholder"
      name="email"
      class="form-control"
    />
  </div>
</template>

<script setup lang="ts">
// TODO merge this with EmailInput.vue once it's  adapted to datafields
import { computed } from 'vue'
import { string } from 'yup'

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: '',
    required: false
  }
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const rule = string().email().trim()

const formattedEmail = computed({
  get() {
    return rule.cast(props.modelValue)?.toString()
  },
  set(value: string) {
    emit('update:modelValue', value)
  }
})
</script>
