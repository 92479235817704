<template>
  <modal-component
    form
    :dialog-class="dialogClass"
    :on-submit="onSubmit"
    :visible="visible"
    @show="emit('show')"
    @shown="emit('shown')"
    @hidden="emit('hidden')"
  >
    <template #modal-button="{ modalId }">
      <slot name="modal-button" :modal-id="modalId" />
    </template>
    <template v-if="hideModalClose" #modal-close>
    </template>
    <template #modal-title>
      <slot name="modal-title" />
    </template>
    <template #modal-body="{ isModalReady }">
      <slot name="modal-body" :is-modal-ready="isModalReady" />
    </template>
    <template v-if="$slots['modal-footer']" #modal-footer >
      <slot name="modal-footer" />
    </template>
  </modal-component>
</template>

<script lang="ts" setup>
import { PropType } from 'vue'
import { asyncComponentWrapper } from '@/composables/useReloadModal'

const ModalComponent = asyncComponentWrapper(() => import('@/components/Misc/ModalComponent.vue'))

defineProps({
  dialogClass: {
    type: String,
    default: ''
  },
  id: {
    required: false,
    default: null,
    type: String
  },
  onSubmit: {
    required: false,
    default: () => {
      return
    },
    type: Function as PropType<{
      (): void
    }>
  },
  visible: {
    required: false,
    default: false,
    type: Boolean
  },
  hideModalClose: {
    type: Boolean,
    required: false,
    default: false,
  }
})

const emit = defineEmits<{
  (e: 'show'): void
  (e: 'shown'): void
  (e: 'hidden'): void
}>()

</script>
