import { AxiosResponse } from 'axios'
import client, { AbortableAxiosRequestConfig } from '@/api/client'
import { BusinessItemResponse, BusinessListResponse } from '@/dto/Business/BusinessDto'
import { BusinessUserProfileResponse } from '@/dto/Auth/ProfileDto'
import { AbilityResponse } from '@/dto/Auth/PermissionDto'
import { BusinessUserRoleResponse } from '@/dto/Auth/RoleDto'
import { DataFieldValuesRequest } from '@/dto/Business/LmsLeadDto'
import { ResponseOne, PaginatedResponse } from '@/api/PaginatedResponse'
import { StoreFileDto } from '@/dto/common/files'

// TODO move and rename DTO
type BusinessUserItem = {
  id: string
  profile: BusinessUserProfileResponse
  abilities: AbilityResponse[]
  roles: BusinessUserRoleResponse[]
}

// TODO move and rename DTO
type BusinessUserResponse = {
  data: BusinessUserItem
}

// TODO move and rename DTO
export interface BusinessRequest extends DataFieldValuesRequest {
  referrer_code?: string
  promo_codes?: string[]
}

export function getBusinesses(
  userId: string,
  slug?: string,
  page?: number,
  search?: string
): Promise<AxiosResponse<PaginatedResponse<BusinessListResponse>>> {
  return client.get(`/user/users/${userId}/businesses`, <AbortableAxiosRequestConfig>{
    _cannotAbort: true,
    params: {
      slug,
      page,
      search
    }
  })
}

export function getBusinessesUsers(
    businessId: string,
    userId: string,
    init: boolean|undefined = undefined
): Promise<AxiosResponse<BusinessUserResponse>> {
  return client.get(`/business/businesses/${businessId}/users/${userId}`, <AbortableAxiosRequestConfig>{
    params: { init },
    _cannotAbort: true
  })
}

export function getBusiness(
  businessId: string
): Promise<AxiosResponse<ResponseOne<BusinessItemResponse>>> {
  return client.get(`/business/businesses/${businessId}`, <AbortableAxiosRequestConfig>{
    _cannotAbort: true
  })
}

export function getBusinessInfo(
  businessId: string
): Promise<AxiosResponse<ResponseOne<BusinessItemResponse>>> {
  return client.get(`/business/businesses/${businessId}/info`, <AbortableAxiosRequestConfig>{
    _cannotAbort: true
  })
}

export function updateBusiness(
  businessId: string,
  business: BusinessRequest
): Promise<AxiosResponse<ResponseOne<BusinessItemResponse>>> {
  return client.put(`/business/businesses/${businessId}`, business)
}

export function createBusinessLogo(
  businessId: string,
  file: StoreFileDto
): Promise<AxiosResponse<ResponseOne<BusinessItemResponse>>> {
  return client.post(`/business/businesses/${businessId}/logo`, {
    file: file
  })
}

export function deleteBusinessLogo(
  businessId: string
): Promise<AxiosResponse<ResponseOne<BusinessItemResponse>>> {
  return client.delete(`/business/businesses/${businessId}/logo`)
}
