import Repository from '@/store/repository'
import { CountryCodeIso2 } from '@/shared'

export interface LocaleSettings {
  language: string
  country: CountryCodeIso2
}

export class PersistedDashboardState {
  locale: LocaleSettings = {
    language: 'en',
    country: CountryCodeIso2.US
  }
  timezone = 'UTC'
}

export default class DashboardRepository extends Repository<PersistedDashboardState> {
  private static localInstance?: DashboardRepository
  private static sessionInstance?: DashboardRepository

  protected get storageKey(): string {
    return 'dashboard_state'
  }

  public static getSessionInstance(): DashboardRepository {
    if (undefined === DashboardRepository.sessionInstance) {
      DashboardRepository.sessionInstance = new DashboardRepository(
        window.sessionStorage
      )
    }

    return DashboardRepository.sessionInstance
  }

  public static getLocalInstance(): DashboardRepository {
    if (undefined === DashboardRepository.localInstance) {
      DashboardRepository.localInstance = new DashboardRepository(
        window.localStorage
      )
    }

    return DashboardRepository.localInstance
  }

  public isStateValid(persistedDashboardState: PersistedDashboardState | null) {
    return null !== persistedDashboardState
  }
}
