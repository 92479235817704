import client, { AbortableAxiosRequestConfig } from '@/api/client'
import { AxiosResponse } from 'axios'
import { ResponseOne, Response } from '@/api/PaginatedResponse'
import { BusinessConfigResponseDTO } from '@/dto/Business/Config/Response'
import { UpdateBusinessConfigRequestDTO } from '@/dto/Business/Config/Request'

export function getBusinessConfig(
  businessId: string
): Promise<AxiosResponse<Response<BusinessConfigResponseDTO>>> {
  return client.get(`/business/businesses/${businessId}/config`, <AbortableAxiosRequestConfig>{
    _cannotAbort: true
  })
}

export function updateBusinessConfig(
  businessId: string,
  request: UpdateBusinessConfigRequestDTO
): Promise<AxiosResponse<ResponseOne<BusinessConfigResponseDTO>>> {
  return client.put(`/business/businesses/${businessId}/config`, request)
}
