export default {
  LEARNING: {
    ACADEMY_REDIRECT: {
      PATH: '/b/:businessSlug/academy',
      NAME: 'academyRedirect'
    },
    HELP_CENTER: {
      PATH: '/help-center',
      NAME: 'help-center'
    }
  }
}
