<template>
  <div>
    <input
      v-bind="$attrs"
      :placeholder="placeholder"
      :disabled="disabled"
      :value="modelValue"
      type="password"
      name="password"
      class="form-control"
      autocomplete="current-password"
      @input="onInput"
    />
  </div>
</template>

<script setup lang="ts">

defineProps({
  modelValue: {
    type: null,
    default: null
  },
  placeholder: {
    type: String,
    default: '',
    required: false
  },
  required: {
    type: Boolean,
    required: false,
    default: false
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  }
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const onInput = (event: Event) => {
  const eventTarget = <HTMLInputElement>event.target

  emit('update:modelValue', eventTarget.value)
}
</script>